import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import Alert from '@mui/material/Alert';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import MuiCardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { QUERY_XLSX_LIQUID_POLICIES } from 'client/app/api/gql/queries';
import { XlsxLiquidPolicy } from 'client/app/gql';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { pluralize } from 'common/lib/format';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import CustomLiquidPolicyIcon from 'common/ui/icons/CustomLiquidPolicyIcon';

type Props = {
  selectedPolicy: string | undefined;
  onSelect: (liquidPolicy: string | undefined) => void;
};

type XlsxPolicyWithSelectedState = XlsxLiquidPolicy & { selected: boolean };

/**
 * FIXME: this view is added for backward compatibility with XLSX liquid policies.
 * It should be removed when XLSX policies are deprecated.
 */
export default function XlsxPoliciesView({ selectedPolicy, onSelect }: Props) {
  const { policies, loading, togglePolicy } = useXlsxLiquidPolicies(
    selectedPolicy,
    onSelect,
  );

  return (
    <Stack gap={8} p={8}>
      <Alert severity="info" color="info">
        <Stack gap={3}>
          <Typography variant="body1" color="inherit">
            Why not upload the liquid types from this custom file to the Inventory to make
            them available for other workflows and users in your organisation!
          </Typography>
          <a href="/#/liquid-policies" target="_blank" rel="noopener noreferrer">
            Go to Inventory
          </a>
        </Stack>
      </Alert>
      {loading ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Stack direction="row" flexWrap="wrap" gap={5} pb={8}>
          {policies.map(policy => (
            <Card
              key={policy.name}
              selected={policy.selected}
              exists={policy.exists}
              onClick={() => togglePolicy(policy)}
            >
              <CardHeader
                avatar={<CustomLiquidPolicyIcon />}
                title={<Typography variant="body1">{policy.name}</Typography>}
              />
              <CardContent>
                <Typography variant="caption">
                  {pluralize(policy.ruleCount, 'rule')}
                </Typography>
                {policy.exists && (
                  <FormHelperText error>
                    Liquid policy with this name already exists
                  </FormHelperText>
                )}
              </CardContent>
            </Card>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

const EMPTY_LIST: XlsxPolicyWithSelectedState[] = [];
/**
 * Parses the encoded XLSX file and returns list of liquid policies.
 */
function useXlsxLiquidPolicies(
  selectedPolicyName: string | undefined,
  onSelect: (liquidPolicy: string | undefined) => void,
) {
  const xlsxFileEncoded = useWorkflowBuilderSelector(
    state => state.config.global.liquidHandlingPolicyXlsxJmpFile,
  );

  if (!xlsxFileEncoded) {
    throw new Error('No XLSX file has been uploaded');
  }

  const [liquidPoliciesState, setLiquidPoliciesState] = useState(EMPTY_LIST);
  const { showError } = useSnackbarManager();

  const { loading } = useQuery(QUERY_XLSX_LIQUID_POLICIES, {
    variables: {
      base64: xlsxFileEncoded,
    },
    /**
     * In case user changes the file and then re-uploads it we want
     * to get a fresh parsing result.
     */
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setLiquidPoliciesState(
        data.xlsxLiquidPolicies.map(p => ({
          ...p,
          selected: p.name === selectedPolicyName,
        })),
      );
    },
    onError(error) {
      showError(error.message);
    },
  });

  const togglePolicy = (policy: XlsxPolicyWithSelectedState) => {
    setLiquidPoliciesState(policies =>
      policies.map(p => ({
        ...p,
        selected: p.exists ? false : p.name === policy.name ? !p.selected : false,
      })),
    );
    if (!policy.exists) {
      // Select if not selected before or deselect to be able to "Select None"
      onSelect(!policy.selected ? policy.name : undefined);
    }
  };

  return {
    policies: liquidPoliciesState,
    loading,
    togglePolicy,
  };
}

const Card = styled(MuiCard, {
  shouldForwardProp: prop => prop !== 'selected' && prop !== 'exists',
})<{
  selected: boolean;
  exists: boolean;
}>(({ theme, selected, exists }) => ({
  width: 336,
  margin: 0,

  outline: exists
    ? `1px solid ${theme.palette.error.main}`
    : selected
    ? `1px solid ${theme.palette.primary.main}`
    : 'none',

  cursor: 'default',
  userSelect: 'none',
}));
const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  padding: theme.spacing(6, 6, 3),
}));
const CardContent = styled(MuiCardContent)(() => ({
  paddingTop: 0,
  paddingLeft: 64,
}));
